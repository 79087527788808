const formatDommus = {
    formatDate: (date, verbose=false) => {
      date = new Date(date);
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " anos atrás";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " mes(es) atrás";
      }
      interval = seconds / 86400;
      if (interval > 1) {        
        return Math.floor(interval) + " dia(s) atrás";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return verbose ? "hoje" : Math.floor(interval) + " hora(s) atrás";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minuto(s) atrás";
      }
      return Math.floor(seconds) + " segundo(s) atrás";
    },

    formatDateProcessInfo: (date) => {
      date = new Date(date);
      let dateNow = new Date();
      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return ` há ${Math.floor(interval)} anos`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return ` há ${Math.floor(interval)} mês(es)`;
      }
      if(dateNow.getDate() - 1 === date.getDate()){
        return "ontem";
      }
      interval = seconds / 86400;
      if (interval > 1) {        
        return ` há ${Math.round(interval)} dias`;
      }
      return "hoje";
    },

    contarAtraso: (date) => {
      date = new Date(date);
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 3) {
          return Math.floor(interval) - 3;
        }
      }
    },

    zeroPad: (num, places) => {
      return String(num).padStart(places, "0");
    },

    formataMysql: (data) => {
      if (data !== undefined && data !== "") {
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2];

        return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
      } else {
        return null;
      }
    },

    formataMysqlDateHour: (data) => {
      if (data !== undefined && data !== "") {
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2].split(" ")[0];
        var hora = data.split("/")[2].split(" ")[1];
        return (
          ano +
          "-" +
          ("0" + mes).slice(-2) +
          "-" +
          ("0" + dia).slice(-2) +
          " " +
          hora +
          ":" +
          "00"
        );
      } else {
        return null;
      }
    },

    calcularIdade: (data) => {
      var now = new Date();
      var yearAge = 0;
      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();
      var dob = new Date(
        data.substring(6, 10),
        data.substring(3, 5) - 1,
        data.substring(0, 2)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      yearAge = yearNow - yearDob;

      let monthAge;

      if (monthNow >= monthDob) monthAge = monthNow - monthDob;
      else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
      }
      let dateAge;
      if (dateNow >= dateDob) dateAge = dateNow - dateDob;
      else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;
        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };
      return age.years;
    },
    isJson: (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  };
  export default formatDommus;
