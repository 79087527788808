import React, { createContext, useEffect, useState, useCallback, useContext } from "react";
import Swal from "sweetalert2";
import AtendentesService from "../services/AtendentesService";
import { DommusContext } from "./DommusContext";

export const AtendentesContext = createContext({});

export function AtendentesProvider({ children }) {
  const { token, user } = useContext(DommusContext);
  const [listaAtendentes, setListaAtendentes] = useState([]);
  const [listaAtendentesGerenciados, setListaAtendentesGerenciados] = useState([]);
  const [mapaAtendentes, setMapaAtendentes] = useState(new Map());
  const [mapaAtendentesGerenciados, setMapaAtendentesGerenciados] = useState(new Map());

  useEffect(() => {
    const novoMapa = new Map();
    listaAtendentes.forEach((atendente) => {
      novoMapa.set(Number(atendente.id), atendente);
    });
    setMapaAtendentes(novoMapa);
  }, [listaAtendentes]);

  useEffect(() => {
    const novoMapa = new Map();
    listaAtendentesGerenciados.forEach((atendente) => {
      novoMapa.set(Number(atendente.id), atendente);
    });
    setMapaAtendentesGerenciados(novoMapa);
  }, [listaAtendentesGerenciados]);

  const recarregarListaAtendentes = () => {
    AtendentesService.listaAtendentesTransferencia(token).then((res) => {
        let arrayAtendentes = res.data.sort((a, b) => {
          return (a.nome && b.nome && a.nome < b.nome && -1) || 0;
        });
        setListaAtendentes(arrayAtendentes);
      })
      .catch((err) =>
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar a lista de atendentes  " + err,
          icon: "error",
        })
      );
  };

  const recarregarListaAtendentesGerenciados = () => {
    AtendentesService.listaAtendentes(token).then((res) => {
        let arrayAtendentes = res.data.sort((a, b) => {
          return (a.nome && b.nome && a.nome < b.nome && -1) || 0;
        });
        setListaAtendentesGerenciados(arrayAtendentes);
      })
      .catch((err) =>
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar a lista de atendentes  " + err,
          icon: "error",
        })
      );
  };

  const resolverNomeAtendente = (idAtendente, padrao = "Não Atribuído") => {
    let atendente = mapaAtendentes.get(Number(idAtendente));
    return (atendente && atendente.nome) || padrao;
  };

  const testarAtendenteGerenciado = (idAtendente) => {
    return mapaAtendentesGerenciados.has(Number(idAtendente));
  };

  useEffect(() => {
    if(user && token){
      recarregarListaAtendentes();
      recarregarListaAtendentesGerenciados();
    }
  }, [user, token]);

  return (
    <AtendentesContext.Provider
      value={{
        listaAtendentes,
        setListaAtendentes,
        listaAtendentesGerenciados,
        setListaAtendentesGerenciados,
        recarregarListaAtendentes,
        recarregarListaAtendentesGerenciados,
        resolverNomeAtendente,
        testarAtendenteGerenciado
      }}
    >
      {children}
    </AtendentesContext.Provider>
  );
}
