import React, { useEffect, useMemo, useRef, useState } from "react";
import { Voip } from "./components/voip";
import { PusherProvider } from "./contexts/PusherContext";
import { VoipProvider } from "./contexts/VoipContext";
import { DommusProvider } from "./contexts/DommusContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { ChatProvider } from "./contexts/ChatContext";
import { AtendentesProvider } from "./contexts/AtendentesContext";
import './App.css';
import './fa-styles.css';
import './swal-styles.css';

import { Chat } from "./components/chat";
import Draggable from "react-draggable";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App({ tenancy, token, user }) {

  const [posicao, setPosicao] = useState({});

  const handleStop = (event) => {
    const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document.body;
	  const container = wrapper.querySelector('.dommus-widgets-app');
    const rect = container.getBoundingClientRect();
    let right = rect.right - rect.width;
    let top = rect.top;
    if(right > window.innerWidth || right < 0){
      right = window.innerWidth - 80;
    }
    if(top > window.innerHeight || top < 0){
      top = window.innerHeight - 150;
    }
    localStorage.setItem('@DommusWidget-posicaoX', right);
    localStorage.setItem('@DommusWidget-posicaoY', top);
    setPosicao({
      x: right,
      y: top
    })
  }

  useEffect(()=>{
    let positionX =  parseFloat(localStorage.getItem('@DommusWidget-posicaoX'));
    if(positionX >= window.innerWidth || !positionX){
      positionX = window.innerWidth - 80;
      localStorage.setItem('@DommusWidget-posicaoX', positionX)
    }

    let positionY =  parseFloat(localStorage.getItem('@DommusWidget-posicaoY'));    
    if(positionY >= window.innerHeight || !positionY){
      positionY = window.innerHeight - 150;
      localStorage.setItem('@DommusWidget-posicaoY', positionY)
    }

    setPosicao({
      x: positionX,
      y: positionY
    })

  },[])


  return (
    <div className="container-root">
      <Draggable
        position={{x: posicao.x ?? 0, y:posicao.y ?? 0}}
        scale={1}
        onStop={handleStop}
        bounds="body"
      >
        <div className="dommus-widgets-app">
          <DommusProvider>
            <PusherProvider>
              <AtendentesProvider>
              <FontAwesomeIcon
                icon={faGripVertical}
                color="grey"
                style={{
                  color: "rgba(75, 75, 75, 0.24)",
                  position: "absolute",
                  top: "41%",
                  right: "4px"
                }}
              />
              <FontAwesomeIcon
                icon={faGripVertical}
                color="grey"
                style={{
                  color: "rgba(75, 75, 75, 0.24)",
                  position: "absolute",
                  top: "41%",
                  left: "4px"
                }}
              />
                <VoipProvider>
                  <Voip tenancy={tenancy} dommusToken={token} user={user} />
                </VoipProvider>
                <ChatProvider>
                  <Chat tenancy={tenancy} dommusToken={token} user={user} />
                </ChatProvider>
              </AtendentesProvider>
            </PusherProvider>
          </DommusProvider>
        </div>
      </Draggable>
    </div>
  );
}

export default App;
