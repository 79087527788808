import React, {createContext, useState} from "react";


export const DommusContext = createContext({});

export function DommusProvider ({children}) {

  const [tenancy, setTenancy] = useState(0);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  return (
    <DommusContext.Provider value={{
      tenancy, setTenancy,
      token, setToken,
      user, setUser
      }}>
      {children}
    </DommusContext.Provider>
  ) 
}
