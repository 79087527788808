import React, { useContext, useState } from "react";
import { VoipContext } from "../../contexts/VoipContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLeft } from "@fortawesome/free-solid-svg-icons";
import  DommusLoading  from '../../components/DommusLoading/DommusLoading';
export function VoipTransfer() {
  const { usersForTransference, setSelectedUserForTransference, transferCall, loading } =
    useContext(VoipContext);

  const [selectedUser, setSelectedUser] = useState("");

  function handleChangeTransfer(event) {
    setSelectedUser(event.target.value);
    setSelectedUserForTransference(event.target.value);
  }

  function handleTransferCall() {
    if (selectedUser) {
      transferCall();
    }
  }
  // todo: Deverá ser possível transferir uma ligação para um telefone externo
  return (
    (loading && <DommusLoading />) ||
    <>
      <div className="d-flex justify-content-between align-items-center">
        <select
          className="form-control"
          value={selectedUser}
          onChange={handleChangeTransfer}
        >
          <option value={0}>Transferir chamada para</option>;
          {usersForTransference.map((item) => {
            return <option value={item.id}>{item.nome}</option>;
          })}
        </select>
        <button className="button-transfer" onClick={handleTransferCall}>
          <FontAwesomeIcon icon={faRightLeft} color="#444444" />
        </button>
      </div>
    </>
  );
}
