import React, {Fragment, useContext, useMemo, useRef, useState } from "react";
import { Col, Container, Row, Nav, Alert } from "react-bootstrap";
import ModalDommus from "../../../components/Modal";
import { ChatContext } from "../../../contexts/ChatContext";
import ChatService from "../../../services/ChatService";
import { DommusContext } from "../../../contexts/DommusContext";
import "./style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "./whatsapp-styles.scss";
import Registro from '../Registro';
import Swal from "sweetalert2";
import "../style-registro.scss";
import Detail from '../Detail';

export default function Modal() {
  const {
    isModalOpen,
    setIsModalOpen,
    chatHistory,
    selectedChat,
    setSelectedChat,
    chatMessages,
    page,
    message,
    setMessage,
    sendMessage,
    setPage,
    setChatHistory,
    totalAtendimentos,
    disabledInputMessage
  } = useContext(ChatContext);
  const {token} = useContext(DommusContext);
  const messageContainerRef = useRef(null);
  const [fetchingLista, setFetchingLista] = useState(false)
  const abrirAtendimento = (atendimento) => {
    if(atendimento && (!selectedChat || ((selectedChat && selectedChat.id) !== atendimento?.id))){
      setSelectedChat(Object.assign({}, atendimento))
      scrollDown()
    }
  }
  const renderChatHistoryItem = () => {

    return <div className="chat-history">
      <Nav fill variant="pills">
        <div className="registros-container w-100">
        {
          (( chatHistory.length || 0 ) &&
          <InfiniteScroll
            dataLength={chatHistory.length || 0} //This is important field to render the next data
            next={fetchScroll}
            hasMore={!(fetchingLista) && ( chatHistory.length < totalAtendimentos )}
            loader={<h3 className="scroll-carregando-msg">Carregando</h3>}
            style={{ minHeight: '350px'}}
            endMessage={((fetchingLista) &&
              <Alert variant={'info'} className="alert-section mt-3">
                <strong>Carregando ...</strong>
              </Alert>
              )
              ||
              <Alert variant={'warning'} className="alert-section mt-3">
                <strong>Não há mais atendimentos a carregar!</strong>
              </Alert>
            }
            scrollThreshold={0.5}
            scrollableTarget="scrollContainer">
              {chatHistory &&
                chatHistory.map((registro, index) => (
                  <div key={registro.id} className="registroSection">
                      <Registro
                      posicaoListaAtentimentos={index}
                      registro={registro}
                      abrirAtendimento={abrirAtendimento}
                      atendimentoAtivoId={(selectedChat && selectedChat.id) || null}
                      key={registro.id}
                      />
                  </div>
                ))}
          </InfiniteScroll>) ||
          <Alert variant={'warning'} className="alert-section">
            <strong>Nenhum atendimento à ser exibido</strong>
          </Alert>
          }
        </div>
      </Nav>
    </div>

  };

  const renderChatInput = () => {
    return selectedChat?.id && !(selectedChat.finalizado_em) && (
      <div className="chatInput d-flex">
        <input
          disabled={disabledInputMessage}
          aria-label="Mensagens"
          type="text"
          className="inputMessage form-control"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          onKeyDown={e => {
            handleSubmitMessageWithKey(e)
          }}
        />
        <button
          disabled={disabledInputMessage}
          type="button"
          className="sendButton btn btn-primary"
          onClick={() => enviarMensagem()}
        >
          <FontAwesomeIcon icon={faPaperPlane} color="white" />
        </button>
      </div>
    );
  };

  const handleSubmitMessageWithKey = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          enviarMensagem();
      }
  };

  const enviarMensagem = () => {
    sendMessage().then(() => {
      scrollDown();
    });
  }

  const scrollDown = function () {
    const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document;
    wrapper.querySelector('.whatsapp-container').scroll(0,0)
  };

  const fetchScroll = () => {
      setFetchingLista(true);
      const paginaBusca = page + 1;
      ChatService.getChatHistory(token, paginaBusca).then(res => {
        if(Array.isArray(res.data) && res.data.length){
          const novaLista = chatHistory.concat(res.data);
          setChatHistory(novaLista);
          setPage(paginaBusca);
          setTimeout(function () {
            setFetchingLista(false);
          }, 10);
        }
      }).catch(err => {
        Swal.fire({
          titleText: "Erro",
          text: "Não foi possivel buscar os dados filtrados",
          icon: 'error'
        });
      })
    };

  return (
    <div>
      <ModalDommus
        backdrop="false"
        open={isModalOpen}
        close={() => {
          setIsModalOpen(false);
          setSelectedChat({});
        }}
        titulo="Mensagens"
        className="chat-modal"
      >
        <Row>
          <Col xs="12" md="5">
            {renderChatHistoryItem()}
          </Col>
          <Col xs="12" md="7" className="messageDetail">
            <Container>
              <Row>
                <Col xs="12">
                  <div id="scrollableDiv" className={"messageContent"}><Detail messageContainerRef={messageContainerRef}/></div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  {renderChatInput()}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </ModalDommus>
    </div>
  );
}
