import {
  faCommentDots,
  faMicrophoneSlash,
  faPhone,
  faPhoneSlash,
  faTimes,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./voip-in-call.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { VoipContext } from "../../../contexts/VoipContext";
import { VoipHistory } from "../VoipHistory";
import { ocultaTelefone } from "../../InputTextMask";
import { CardAnotations } from "./card-anotations";
import { DommusContext } from "../../../contexts/DommusContext";

export function VoipInCall({setDialerOpen}) {
  const {
    handleHangUp,
    minutes,
    seconds,
    voipHistory,
    connection,
    callToNumber,
    handleMuteCall,
    isMuted,
    atendimentoAtivo,
  } = useContext(VoipContext);
  const [openAnotations, setOpenAnotations] = useState(false);  

  const [noteList, setNoteList] = useState([]);
  
  useEffect(()=>{    
    if(atendimentoAtivo?.detalhes){
      let anotacoes = JSON.parse(atendimentoAtivo?.detalhes)?.anotacoes ?? [];
      setNoteList(anotacoes);
    }
  },[atendimentoAtivo])

  const handleOpenModalAnotacoes = ()=>{
    setOpenAnotations(!openAnotations);    
  }

  const top = useMemo(()=>{
    const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document.body;
    const container = wrapper.querySelector('.dommus-widgets-app');
    const rect = container.getBoundingClientRect();
    if(rect.top < 350){
      return 0
    }else if(window.innerHeight - rect.bottom < 100){
      return -300
    }else{
      return -200
    }
  },[])

  const right = useMemo(()=>{
    const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document.body;
    const container = wrapper.querySelector('.dommus-widgets-app');
    const rect = container.getBoundingClientRect();
    if(rect.left > 320){
      return 75;
    }else{
      return -320;
    }
  },[])

  return (
    <>
    <div className="in-call" style={{top: top, right: right}}>
      <div className="header-section">
        <div className="title-header">
          <FontAwesomeIcon icon={faPhone} className="header-icon" />
          <span>Voip</span>
        </div>
        <button
          className="header-btn-close"
          onClick={() => {
            setDialerOpen(false);
            setOpenAnotations(false);
          }}
        >
          <FontAwesomeIcon icon={faTimes} color="gray" />
        </button>
      </div>
      <div className="user-section">
        <FontAwesomeIcon icon={faUserCircle} size="5x" color="#515151" />
        <div className="d-flex justify-content-between align-items-center w-100 flex-column">
          <span className="user-name">{atendimentoAtivo?.nome || "Não encontrado"}</span>
          <span className="user-phone">
            {ocultaTelefone(callToNumber || "----")}
          </span>
        </div>
      </div>
      <div className="call-info-section">
        {(connection._status === "open" && (
          <>
            <span className="time-call">
              {String(minutes).padStart(2, "0") +
                ":" +
                String(seconds).padStart(2, "0")}
            </span>
          </>
        )) || <span className="starting-call text-center"> Iniciando chamada. Aguarde... </span>}
      </div>
      <div className="call-buttons-section">        
        <button className={`btn big-button ${isMuted ? " is-mute-call " : " mute-call "}`} 
                onClick={()=>{handleMuteCall()}} 
                disabled={connection._status !== "open"}
        >
          <FontAwesomeIcon icon={faMicrophoneSlash} />
        </button>
        <button
          className="btn big-button hangup-call"
          onClick={() => {
            handleHangUp();
          }}
        >
          <FontAwesomeIcon icon={faPhoneSlash} />
        </button>
        {/* <div>{voipHistory.length > 0 && <VoipHistory />}</div> */}
      </div>
      <button className="btn-anotation" onClick={()=>{handleOpenModalAnotacoes()}}>
        <FontAwesomeIcon icon={faCommentDots} />
        <span>Anotações</span>
      </button>
    </div>
    {openAnotations && <CardAnotations setOpen={setOpenAnotations} noteList={noteList} setNoteList={setNoteList}/>}
    </>
  );
}
