import React, { useContext, useState } from "react";
import ModalDommus from "../../../components/Modal";
import { VoipContext } from "../../../contexts/VoipContext";
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./style.css";
export default function ModalHistory({isModalOpen, setIsModalOpen}) {
  const [isHistoryCall, setIsHistoryCall] = useState('')
  const {
    voipHistory, inCall, setInCall, handleNewVoipCall, handleHangUp, setSelectedVoipNumber
  } = useContext(VoipContext);

  function handleNewCall(item){
    setIsHistoryCall(item.id)
    setSelectedVoipNumber(item.contato_origem);
    handleNewVoipCall(item.contato_destino)
  }

  return (
    <div>
      <ModalDommus
        size="lg"
        backdrop="false"
        open={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
        titulo="Histórico de chamadas"
      >
       <div>
          {voipHistory.map((item, key) => {
            return <div className="history-item" key={key}>
              <div><span className="field-type">Nome: </span>{item.nome}</div>
              <div><span className="field-type">Telefone: </span>{item.contato_destino}</div>
              <div><span className="field-type">Tipo de atendimento: </span>{item.tipo_atendimento.descricao}</div>
              <div><span className="field-type">Situação: </span>{item.situacao_atendimento.nome}</div>
              <div><span className="field-type">Chamada a partir de: </span>{item.contato_origem}</div>
              {!inCall && <div className="number callbutton" onClick={() => {setInCall(true)}}>
                <FontAwesomeIcon icon={faPhone} color="green" onClick={() => handleNewCall(item)} />
              </div>}
              {inCall && isHistoryCall === item.id && <div className="number callbutton in-call" onClick={() => {setInCall(false)}}>
                <FontAwesomeIcon icon={faPhone} color="white" onClick={() => handleHangUp()} />
              </div>}
            </div>
          })}
       </div>
      </ModalDommus>
    </div>
  );
}
