import React, { useContext, useEffect, useState } from "react";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VoipDialer } from "./VoipDialer";
import { DommusContext } from "../../contexts/DommusContext";
import Modal from "./Modal";
import './style.css';
import { VoipContext } from "../../contexts/VoipContext";
import { InCallVoip, VoipInCall } from "./in-call/voip-in-call";

export function Voip({ tenancy, dommusToken, user }) {
  const [isDialerOpen, setIsDialerOpen] = useState(false);
  const { setTenancy, setToken, setUser, } = useContext(DommusContext);
  const { inCall } = useContext(VoipContext);

  useEffect(() => {
    setTenancy(tenancy);
    setToken(dommusToken);
    setUser(user);
  }, [tenancy, dommusToken, user]);

  return (
    <>
    <div className={'widget-button'} onClick={() => {
          setIsDialerOpen(!isDialerOpen);
        }}>
      <FontAwesomeIcon
        icon={faPhoneVolume}
        color="green"
      />
    </div>

      {isDialerOpen && (inCall ? <VoipInCall setDialerOpen={setIsDialerOpen}/> : <VoipDialer setDialerOpen={setIsDialerOpen}/>)}
      <Modal />
    </>
  );
}
