import React, {Fragment, useContext, useMemo} from "react";
import { ChatContext } from "../../../contexts/ChatContext";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Alert } from "react-bootstrap";
import MensagemConteudoAtendimentoWhatsapp from '../../MensagemConteudoAtendimentoWhatsapp';

export default function Detail(){
  const {
    chatMessages,
    selectedChat,
    hasMore,
    messagesPage,
    getChatMessages,
    setMessagesPage
  } = useContext(ChatContext);
  const returnMessagesWhatsapp = useMemo(() => {
        if (chatMessages && chatMessages.length) {
            let listIterar = Array.from(chatMessages.values()).filter(msg => msg && msg?.detalhes).reverse();
            let date = moment().format('DD/MM/YYYY');;
            return listIterar.map((msg, indice) => {
                let isLast = (indice === (listIterar.length - 1));
                let separator = false;
                let separatorLast = false;

                let msg_date = moment(msg.criado_em).format('DD/MM/YYYY');
                if ((date !== msg_date)) {
                    separator = <div className="separador">{date}</div>;
                    date = msg_date;
                }
                if(isLast){
                  separatorLast = <div className="separador">{msg_date}</div>;
                }
                return <Fragment key={msg.id}>
                  {separator}
                  <MensagemConteudoAtendimentoWhatsapp conteudo={msg}/>
                  {separatorLast}
                </Fragment>
            });
        }
        return <></>;

    }, [chatMessages]);

  const returnMessages = useMemo(() => {
    if(chatMessages && chatMessages.length){
      let messages = chatMessages.filter((msg) => msg && msg.detalhes).reverse();
      return messages.map((item) => {
        return (
          <div
            key={item.id + item.id_atendimento}
            className={`messageItem ${item.direcao}`}
          >
            {item.conteudo}
            <div>{moment(item.atualizado_em).format("DD/MM/YYYY HH:mm")}</div>
          </div>
        );
      });
    }
    return <></>;

  }, [chatMessages]);

  const loadMore = () => {
    const actualPage = messagesPage + 1;
    getChatMessages(actualPage);
    setMessagesPage(actualPage);

  };


  return <>{( chatMessages && chatMessages.length && (
    <InfiniteScroll
      dataLength={chatMessages.length}
      className="whatsapp-container"
      next={loadMore}
      style={{ display: "flex", flexDirection: "column-reverse", height: "calc(100vh - 13.5rem)" }}
      hasMore={hasMore}
      height={500}
      inverse={true}
      loader={<h4>Carregando...</h4>}
      scrollableTarget="scrollableDiv"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Não existem mais mensagens a carregar</b>
        </p>
      }
    >
      { ( selectedChat?.meio_comunicacao?.slug === 'whatsapp' && returnMessagesWhatsapp ) || returnMessages}
    </InfiniteScroll>
  ) ) || <Alert variant={"info"}>Nenhuma mensagem a exibir.</Alert>}</>;
}
