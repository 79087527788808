import React, { useContext, useEffect, useRef, useState } from "react";
import "./item-anotations.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import AtendimentoService from "../../../services/AtendimentoService";
import { VoipContext } from "../../../contexts/VoipContext";
import Swal from "sweetalert2";
import { DommusContext } from "../../../contexts/DommusContext";

export function ItemAnotations({ note, handleExclusao, setNoteList }) {
  const {atendimentoAtivo} = useContext(VoipContext);
  const {token} = useContext(DommusContext);
  const [isEditableMode, setIsEditableMode] = useState(false);
  const anotacaoRef = useRef(null);


  const handleSaveNote = ()=>{
    let value = anotacaoRef?.current?.value ?? null;
    if(value){
      AtendimentoService.salvarAnotacao(token, atendimentoAtivo.id, value, note.id).then((response)=>{
        setNoteList(response.data);
        setIsEditableMode(false);
      }).catch((error)=>{
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao salvar a anotação  " + error,
          icon: "error",
        })
      });
    }
  }

  useEffect(()=>{
      if(isEditableMode){
        anotacaoRef?.current.focus();
        let end = anotacaoRef.current.value.length;
        anotacaoRef.current.setSelectionRange(end, end);
      }
  },[isEditableMode])

  return (
    <div className="anotation-item">
      <div className="anotation">
        {isEditableMode ? (
          <textarea
            className="note-input"
            defaultValue={note?.anotacao || ""}
            ref={anotacaoRef}
          />          
          // <span className="textarea" role="textbox" contentEditable ref={anotacaoRef}></span>
        ) : (
          <span>{note?.anotacao}</span>
        )}
      </div>
  
    <div className="btn-anotation-section">
        {isEditableMode ? (
          <>
            <button
              className="btn-anotation"
              style={{ color: "var(--dommus-color-green)" , fontSize: "18px"}}
              onClick={() => {
                handleSaveNote();
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button
              className="btn-anotation"
              style={{ color: "var(--dommus-color-red)" , fontSize: "18px"}}
              onClick={()=>{
                setIsEditableMode(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </>
        ) : (
          <>
            <button
              className="btn-anotation"
              style={{ color: "var(--dommus-color-secondary-light)" }}
              onClick={() => {
                setIsEditableMode(true);                
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              className="btn-anotation"
              style={{ color: "var(--dommus-color-red)" }}
              onClick={()=>{handleExclusao(note)}}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        )}
      </div>
    </div>
  );
}
