import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalDommus from "../../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faPhone,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { VoipContext } from "../../../contexts/VoipContext";
import "./style.css";
import { VoipTransfer } from "../VoipTransfer";

export default function Modal() {
  const {
    connection,
    handleAnswerCall,
    handleHangUp,
    isModalOpen,
    setIsModalOpen,
    callData,
    seconds,
    minutes,
    inCall,
  } = useContext(VoipContext);

  return (
    <div>
      <ModalDommus
        size="sm"
        backdrop="false"
        open={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
        titulo="Ligação Recebida"
      >
        <div className="corpoLigacaoRecebida">
          <div className="info">
            <div className="fotoEnvolvido">
              <FontAwesomeIcon icon={faUserCircle} color="#444444" />
            </div>
          </div>
          <br />
          <div className="text-center">
            {callData !== null && callData.nome ? callData.nome : ""}
            <br />
            <b>
              {callData !== null && callData.contato ? callData.contato : ""}
            </b>
          </div>
          <br />
          <div className="stage">
            <div className="dot-elastic" />
          </div>
          {inCall && (
            <>
              <div className="d-flex justify-content-between align-items-center w-100 flex-column">
                {minutes + ":" + seconds}
              </div>
              <VoipTransfer />
            </>
          )}
          <div className="botoesAcao">
            {!inCall && connection && (
              <OverlayTrigger
                key="buttonAtender"
                placement="top"
                overlay={<Tooltip id={`tooltip-atender`}>Atender</Tooltip>}
              >
                <button className="botaoAtender" onClick={handleAnswerCall}>
                  <FontAwesomeIcon icon={faPhone} color="#fff" />
                </button>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              key="buttonRejeitar"
              placement="top"
              overlay={<Tooltip id={`tooltip-rejeitar`}>Rejeitar</Tooltip>}
            >
              <button className="botaoRejeitar" onClick={handleHangUp}>
                <FontAwesomeIcon icon={faPhoneSlash} color="#fff" />
              </button>
            </OverlayTrigger>
          </div>
          <p className="text-center">
            Ligando para:{" "}
            {callData !== null && callData.label ? callData.label : ""}
          </p>
        </div>
      </ModalDommus>
    </div>
  );
}
