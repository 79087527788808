const urlHelper = {
  download: (link) => {
    let fileName;
    fileName = link.split("/").slice(-1)[0];

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", `https://arquivos.dommus.com.br/${fileName}`);
    form.setAttribute("target", "_blank");
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = "file";
    input.value = link;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  },
};

export default urlHelper;
