import React, { useContext, useMemo, useState } from "react";
import { faBackspace, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./voip-dialer.css";
import { VoipContext } from "../../contexts/VoipContext";
import { VoipHistory } from "./VoipHistory";
import { celularMask } from "../InputTextMask";
import { LongPressDetectEvents, useLongPress } from "use-long-press";

export function VoipDialer({setDialerOpen}) {
  const [callTo, setCallTo] = useState("");

  const {
    voipNumbers,
    selectedVoipNumber,
    setSelectedVoipNumber,
    handleNewVoipCall,
    inCall,
    setInCall,
    voipHistory,
  } = useContext(VoipContext);

  const longPress = useLongPress(()=>{handleEraseAllNumber()}, {});

  function handleNewCall(digit) {    
    if (callTo.length < 11) {
      setCallTo((current) => current + digit);
    }
  }

  function handleEraseNumber() {
    setCallTo((current) => current.slice(0,-1));
  }
  
  function handleEraseAllNumber() {
    setCallTo("");
  }

  function handleSelectedVoipNumber(event) {
    setSelectedVoipNumber(event.target.value);
  }

  const top = useMemo(()=>{
    const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document.body;
    const container = wrapper.querySelector('.dommus-widgets-app');
    const rect = container.getBoundingClientRect();
    if(rect.top < 350){
      return 0
    }else if(window.innerHeight - rect.bottom < 100){
      return -370
    }else{
      return -200
    }
  },[])

  const right = useMemo(()=>{
    const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document.body;
    const container = wrapper.querySelector('.dommus-widgets-app');
    const rect = container.getBoundingClientRect();
    if(rect.left > 320){
      return 75;
    }else{
      return -320;
    }
  },[])


  return (
    <>
      <div className="dialer" style={{top: top, right: right}}>
        <div className="header-section">
          <div className="title-header">
            <FontAwesomeIcon icon={faPhone} className="header-icon" />
            <span>Voip</span>
          </div>
          <button
            className="header-btn-close"
            onClick={() => {
              setDialerOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} color="gray" />
          </button>
        </div>
        <div className="origem-section">
          <span>Origem: </span>
          <select
            className="form-control origem-select"
            value={selectedVoipNumber || 0}
            onChange={handleSelectedVoipNumber}
          >
            <option value={0} hidden={true}>
              Selecione a origem
            </option>
            {voipNumbers.map((item, index) => {
              return (
                <option key={index} value={item.valor}>
                  {item.descricao}
                </option>
              );
            })}
          </select>
        </div>
        {callTo.length > 0 && (
          <div className="call-to-section">
            {celularMask(callTo)}{" "}
            <FontAwesomeIcon
              icon={faBackspace}
              color="gray"
              {...longPress()}
              onClick={() => handleEraseNumber()}
            />
          </div>
        )}
        <div className="numbers-section">
          <div className="numbers-row">
            <div
              className="number"
              onClick={() => {
                handleNewCall(1);
              }}
            >
              1
            </div>
            <div
              className="number"
              onClick={() => {
                handleNewCall(2);
              }}
            >
              2
            </div>
            <div
              className="number"
              onClick={() => {
                handleNewCall(3);
              }}
            >
              3
            </div>
          </div>
          <div className="numbers-row">
            <div
              className="number"
              onClick={() => {
                handleNewCall(4);
              }}
            >
              4
            </div>
            <div
              className="number"
              onClick={() => {
                handleNewCall(5);
              }}
            >
              5
            </div>
            <div
              className="number"
              onClick={() => {
                handleNewCall(6);
              }}
            >
              6
            </div>
          </div>
          <div className="numbers-row">
            <div
              className="number"
              onClick={() => {
                handleNewCall(7);
              }}
            >
              7
            </div>
            <div
              className="number"
              onClick={() => {
                handleNewCall(8);
              }}
            >
              8
            </div>
            <div
              className="number"
              onClick={() => {
                handleNewCall(9);
              }}
            >
              9
            </div>
          </div>
          <div className="numbers-row">
            <div
              className="number"
              onClick={() => {
                handleNewCall(0);
              }}
            >
              0
            </div>
          </div>
        </div>
        <div className="footer-section row align-items-center">
          <div className="col make-call-btn-footer"></div>
          <div className="col make-call-btn-footer" align="center">
            {!inCall && (
              <div
                className="start-call"
                onClick={() => handleNewVoipCall(callTo)}
                onHol
              >
                <FontAwesomeIcon icon={faPhone} size="xl"/>
              </div>
            )}
          </div>
          <div className="col history-footer" align="right">
            {voipHistory.length > 0 && <VoipHistory />}
          </div>
        </div>
      </div>
    </>
  );
}
