import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf, faPaperPlane, faCheck, faCheckDouble, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function IconSituacaoMensagemWhatsapp({situacao}){

  let icon = faHourglassHalf;
  let estiloIcone = {marginLeft: "0.5rem"};
  let textoOverlay = "Aguardando...";
  switch (situacao) {
    case 'aceita':
      icon = faPaperPlane;
      textoOverlay = "Enviando...";
      break;
    case 'enviado':
      icon = faCheck;
      textoOverlay = "Mensagem enviada.";
      break;
    case 'entregue':
      icon = faCheckDouble;
      textoOverlay = "Mensagem entregue.";
      break;
    case 'lida':
      icon = faCheckDouble;
      textoOverlay = "Mensagem lida.";
      estiloIcone = Object.assign( estiloIcone, {color: "#34B7F1"});
      break;
    case 'falhou':
      icon = faExclamationCircle;
      textoOverlay = "Erro. Mensagem não entregue.";
      estiloIcone = Object.assign( estiloIcone, {color: "var(--dommus-color-red)"});
      break;
    default:
      break;
  }

  return <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip style={estiloIcone}>
            {textoOverlay}
          </Tooltip>}
        >
          <FontAwesomeIcon icon={icon} style={estiloIcone} />
        </OverlayTrigger>

}
