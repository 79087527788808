import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.css';

export default function ModalDommus({children, ...props}) {
	const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document.body;
	const container = wrapper.querySelector('.container-root');
	return <Modal show={props.open} onHide={props.close} size={props.size || "lg"}
								className={props.className}
								centered
								container={container}
								backdrop={(props.backdrop || true)}
								style={{
									paddingRight: '17px',
									display: 'block'
								}}>
		<Modal.Header closeButton>
			<Modal.Title><h2>{props.titulo}</h2></Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className={"conteudo"}>
				{props.content}
				{children}
			</div>
		</Modal.Body>
	</Modal>;
}
