import React, { useContext, useEffect } from "react";

import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DommusContext } from "../../contexts/DommusContext";
import { ChatContext } from "../../contexts/ChatContext";
import Modal from "./Modal";
import "./style.css";

export function Chat({ tenancy, dommusToken, user }) {
  const { isModalOpen, setIsModalOpen, countUnreadMessages } =
    useContext(ChatContext);
  const { setTenancy, setToken, setUser } = useContext(DommusContext);

  useEffect(() => {
    setTenancy(tenancy);
    setToken(dommusToken);
    setUser(user);
  }, [tenancy, dommusToken, user]);

  return (
    <>
      <div
        className="widget-chat-button"
        onClick={() => {
          setIsModalOpen(!isModalOpen);
        }}
      >
        <FontAwesomeIcon icon={faMessage} color="green" />
        {countUnreadMessages > 0 && (
          <div className="unreadMessagesCount">{countUnreadMessages}</div>
        )}
      </div>

      <Modal />
    </>
  );
}
