import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useStopwatch } from "react-timer-hook";
import { PusherContext } from "./PusherContext";
import { Device } from "twilio-client";
import VoipService from "../services/VoipService";
import { DommusContext } from "./DommusContext";
import {errorToast} from '../components/DommusToast';
import Swal from "sweetalert2";

export const VoipContext = createContext({});

export function VoipProvider({ children }) {
  const { seconds, minutes, start, reset } = useStopwatch({
    autoStart: false,
  });
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [connection, setConnection] = useState(false);
  const [inCall, setInCall] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callData, setCallData] = useState(null);
  const [selectedUSerForTransference, setSelectedUserForTransference] =
    useState(null);
  const [usersForTransference, setUsersForTransference] = useState([]);
  const [voipHistory, setVoipHistory] = useState([]);
  const [voipNumbers, setVoipNumbers] = useState([]);
  const [selectedVoipNumber, setSelectedVoipNumber] = useState(null);
  const { token, user, tenancy } = useContext(DommusContext);
  const { channel } = useContext(PusherContext);
  const [cacheCallSidTransferencia, setCacheCallSidTransferencia] = useState(null);
  const [loading, setLoading] = useState(false);
  const [callToNumber, setCallToNumber] = useState('');
  const [atendimentoAtivo, setAtendimentoAtivo] = useState({});
  

  useEffect(() => {
    if (!connection && !twilioDevice) {
      configureDevice();
    }
    if (token) {
      getAvailableForTransference();
      getVoipNumbers();
      getVoipHistory();
    }
  }, [user, token]);

  useEffect(() => {
    channel.unbind("voip_recebido." + user);
    channel.bind("voip_recebido." + user, function ({ data }) {
      if (!isModalOpen) {
        setCallData(data);
        receiveCall(data.connection_socket);
      }
    });
    return () => {
      channel.unbind("voip_recebido." + user);
    }
  }, [twilioDevice]);

  function endCall() {
    reset(null, false);
    setIsModalOpen(false);
    setInCall(false);
    setIsMuted(false);
    setConnection(false);
  }
  function configureDevice() {
    const device = new Device();
    device.on("disconnect", function () {
      endCall();
      device.destroy();
    });
    device.on("cancel", function () {
      endCall();
      device.destroy();
    });
    device.on("incoming", function (conn) {
      setConnection(conn);
      setIsModalOpen(true);
    });
    device.on("connect", function (conn) {
      if(conn.customParameters.get('direcao') === 'enviado'){
        VoipService.novoIniciarChamada(conn.customParameters.get('id_atendimento'), conn.parameters.CallSid, conn.customParameters.get('token')).then(res => {
          const novoAtendimentoAtivo = Object.assign({}, atendimentoAtivo);
          setAtendimentoAtivo(novoAtendimentoAtivo);
        }).catch(err => {
          console.error(err)
        })
      }
      setConnection(conn);
      setInCall(true);
      start();
    });
    device.on("error", function (error) {
      console.log("ERROR Twilio: " + error.message);
      endCall();
    });
    setTwilioDevice(device);
  }

  const receiveCall = useCallback(
    (connection_socket) => {
      if (
        twilioDevice != undefined &&
        twilioDevice != null &&
        twilioDevice.token == null
      ) {
        twilioDevice.setup(connection_socket, {
          debug: true,
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
        });
      }
    },
    [twilioDevice]
  );

  function handleRejectCall() {
    if (connection) {
      connection.reject();
      endCall();
    }
  }

  function handleAnswerCall() {
    setInCall(true);
    connection.accept();
  }
  function handleHangUp() {
    twilioDevice.disconnectAll();
    endCall();
  }
  function handleMuteCall() {    
    twilioDevice.activeConnection().mute(!isMuted);
    setIsMuted(!isMuted);
  }

  function getAvailableForTransference() {
    VoipService.getAvailableForTransference(token).then((res) => {
      const userList = res.data.sort((a, b) => {
        return (a.nome && b.nome && a.nome < b.nome && -1) || 0;
      });
      setUsersForTransference(userList);
    });
  }

  function getVoipNumbers() {
    VoipService.getVoipNumbers(token).then((res) => {
      const numbers = res.data.sort((a, b) => {
        return (
          (a.descricao && b.descricao && a.descricao < b.descricao && -1) || 0
        );
      });
      setVoipNumbers(numbers);
    });
  }

  function getVoipHistory() {
    VoipService.getVoipHistory(token).then((res) => {
      setVoipHistory(res.data);
    });
  }

  function transferCall() {
    let conteudoAtual = Array.isArray(callData.conteudos) && [...callData.conteudos].pop();
    let detalhes = conteudoAtual && JSON.parse(callData?.detalhes);
    setLoading(true);
    setCacheCallSidTransferencia(detalhes?.CallSid);
    VoipService.transferCall(selectedUSerForTransference, token, callData.id);
    endCall();
  }

  useEffect(() => {
    if(channel){
      channel.unbind('chamada-transferida');
    }
    if(channel && callData){
      channel.bind('chamada-transferida', function(data) {
        let idUsuario = Number(user);
        if((data.data.id_atendente === idUsuario) && (Number(data.data.id_atendimento) === callData.id)){
          handleHangUp();
          endCall();
          setLoading(false);
          setCacheCallSidTransferencia(null)
          Swal.fire({
              titleText: "Sucesso!",
              text: "Chamada transferida com sucesso.",
              icon: 'success'
          });
        }
      });
    }
    return () => {
      if(channel){
         channel.unbind('chamada-transferida');
      }
    };
  }, [channel, callData, user]);

  useEffect(() => {
      if(channel){
        channel.unbind('chamada-encerrada-transferencia');
        channel.bind('chamada-encerrada-transferencia', (data) => {
            if((data.CallSid === cacheCallSidTransferencia)){
              handleHangUp();
              setLoading(false);
              endCall();
              errorToast.fire({text: 'Ligação perdida.'})
            }
        });
      }

      return () => {
        channel && channel.unbind('chamada-encerrada-transferencia');
      };
    }, [channel, cacheCallSidTransferencia]);

  function handleNewVoipCall(number) {
    setInCall(true);
    if (selectedVoipNumber) {
      VoipService.getToken(token).then((response) => {
        twilioDevice.setup(response.data, {
          debug: true,
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
        });
        newCall(number);
        setCallToNumber(number);
      });
    } else {
      setInCall(false);
      errorToast.fire({text:"Selecione a origem para iniciar o atendimento"})
      endCall();
    }
  }

  const newCall = useCallback(
    (number) => {
      const call = {
        situacao: "2",
        tipo_atendimento: "1",
        meio_comunicacao: "voip",
        contato_origem: selectedVoipNumber,
        contato_destino: number,
        nome: "Chamada originada através de outro módulo",
      };
      VoipService.newCall(token, call).then((response) => {        
        start();
        const data = {
          id_atendimento: response.data.id,
          id_cliente: tenancy,
          contato_externo: response.data.contato_destino,
          contato_interno: selectedVoipNumber,
          direcao: "enviado",
          token: token,
        };
        setAtendimentoAtivo(response.data);
        twilioDevice.connect(data);
      });
    },
    [selectedVoipNumber, twilioDevice]
  );

  return (
    <VoipContext.Provider
      value={{
        seconds,
        minutes,
        inCall,
        isMuted,
        setInCall,
        connection,
        handleAnswerCall,
        handleHangUp,
        handleMuteCall,
        isModalOpen,
        setIsModalOpen,
        callData,
        usersForTransference,
        setSelectedUserForTransference,
        transferCall,
        voipNumbers,
        selectedVoipNumber,
        setSelectedVoipNumber,
        handleNewVoipCall,
        voipHistory,
        cacheCallSidTransferencia,
        setCacheCallSidTransferencia,
        loading,
        callToNumber,
        atendimentoAtivo,
      }}
    >
      {children}
    </VoipContext.Provider>
  );
}
