import { formatDate } from "../utils/date";
import api from "./api";

const VoipService = {
  getAvailableForTransference: function (token) {
    return api.get("/atendentes-transferencia", {
      headers: {
        Authorization: token,
      },
    });
  },
  transferCall: function (atendente, token, atendimento) {
    return api.put(`atendimento/${atendimento}`, {
      atendente
    }, {
      headers: {
        Authorization: token,
      },
    });
  },
  getToken: function (token){
    return api.post(`atendimento/voip/get-token`, {
    }, {
      headers: {
        Authorization: token,
      },
    });
  },
  getVoipNumbers: function (token){
    return api.get("/meio-comunicacao/contato/voip", {
      headers: {
        Authorization: token,
      },
    });
  },
  newCall: function (token, params){
    return api.post(`atendimento`, params, {
      headers: {
        Authorization: token,
      },
    });
  },
  getVoipHistory: function (token){
    const currentDate =  new Date()
    return api.get(`/atendimento?filtros=%7B%22data_inicio%22:%22${formatDate(currentDate)}%22,%22data_fim%22:%22${formatDate(currentDate)}%22,%22meio_comunicacao%22:[3]%7D`, {
      headers: {
        Authorization: token,
      },
    });
  },
  novoIniciarChamada: function (idAtendimento, callSid, token){
    return api.get(`/atendimento/voip/novo-iniciar/${idAtendimento}/${callSid}`, {
      headers: {
        Authorization: token,
      },
    });
  }

};

export default VoipService;
