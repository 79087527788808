import React, {useEffect, useState, useContext, useCallback} from 'react';
import IconSituacaoMensagemWhatsapp from '../IconSituacaoMensagemWhatsapp';
import { AtendentesContext } from '../../contexts/AtendentesContext';
import { ChatContext } from '../../contexts/ChatContext';
import RecebeImages from './Images';
import RecebeVideo from './Video';
import RecebeAudio from './Audio';
import RecebeText from './Text';
import FileDownload from "./FileDownload";
import moment from "moment";
import { PusherContext } from "../../contexts/PusherContext";

export default function MensagemConteudoAtendimentoWhatsapp({conteudo}){
  const {resolverNomeAtendente} = useContext(AtendentesContext);
  const [detalhes, setDetalhes] = useState({});
  const [cadastradoPor, setCadastradoPor] = useState(null);
  const { channel } = useContext(PusherContext);
  const {selectedChat} = useContext(ChatContext);

  useEffect(() => {
    if(typeof conteudo.detalhes === 'string'){
      const novoDetalhes = JSON.parse(conteudo.detalhes);
      setDetalhes(novoDetalhes);

    }else{
      const novoDetalhes = conteudo.detalhes || {};
      setDetalhes(novoDetalhes);

    }
  }, [conteudo.detalhes]);

  useEffect(() => {
    const cadastrado_por = (conteudo.direcao === 'enviado' && resolverNomeAtendente(conteudo.criado_por)) || (selectedChat.nome || "Destinatário");
    setCadastradoPor(cadastrado_por);
  }, [conteudo.criado_por]);

  useEffect(() => {
    if(channel && conteudo && conteudo.id && detalhes){
      channel.unbind('situacaoMensagemAtualizada.' + conteudo.id);
      channel.bind('situacaoMensagemAtualizada.' + conteudo.id, function (data) {
          const novoDetalhes = Object.assign({}, detalhes);
          novoDetalhes.situacao = data.situacao;
          setDetalhes(novoDetalhes);
      });
    }
  }, [channel, conteudo, detalhes, setDetalhes])

  const resolverConteudoMensagem = useCallback(()=>{
    let msgContent;
    switch (detalhes.type) {
        case 'img':
            msgContent =
                <RecebeImages conteudo={conteudo.conteudo} url={detalhes.url} />
            break;
        case 'video':
            msgContent =
                <RecebeVideo conteudo={conteudo.conteudo} url={detalhes.url} />
            break;
        case 'audio':
            msgContent =
                <RecebeAudio conteudo={conteudo.conteudo} url={detalhes.url} />
            break;
        case 'text':
            msgContent =
                <RecebeText conteudo={conteudo.conteudo} />
            break;

        case 'file':
        case 'msword':
        case 'pdf':
            detalhes.url ?
                msgContent = <FileDownload conteudo={"Anexo " +
                 (((conteudo.direcao === 'recebido') && 'recebido') || 'enviado')}
                 url={detalhes.url} />
                :
                msgContent = <RecebeText conteudo={conteudo.conteudo} />
            break;

        default:
            msgContent = <RecebeText conteudo={conteudo.conteudo} />
            break;
    }

    return msgContent
  }, [detalhes.type, conteudo.conteudo]);

  return <>
      <div className={'data ' + ((conteudo && conteudo.direcao) === 'recebido' ? 'recebida' : 'enviada')}>
          {cadastradoPor} - {moment(conteudo.criado_em).format('HH:mm')} {detalhes && detalhes.situacao && <IconSituacaoMensagemWhatsapp situacao={detalhes.situacao} />}
      </div>
      <div className={'mensagem-item ' + (conteudo.direcao === 'recebido' ? 'recebida' : 'enviada')}>
          {resolverConteudoMensagem()}
      </div>
  </>
}
