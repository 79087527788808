import api from "./api";
const AtendentesService = {
  listaAtendentes: (token) => {
    return api.get('/atendentes',
    {
      headers: {
        Authorization: token,
      },
    })
  },
  listaAtendentesTransferencia: (token) => {
    return api.get('/atendentes-transferencia',
    {
      headers: {
        Authorization: token,
      },
    })
  }
}

export default AtendentesService;
