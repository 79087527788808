import { React, useContext, useRef } from "react";
import "./card-anotations.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import { faCommentDots, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ItemAnotations } from "./item-anotations";
import AtendimentoService from "../../../services/AtendimentoService";
import { VoipContext } from "../../../contexts/VoipContext";
import Swal from "sweetalert2";
import { DommusContext } from "../../../contexts/DommusContext";


export function CardAnotations({ setOpen, noteList, setNoteList}) {
  const {atendimentoAtivo} = useContext(VoipContext)
  const {token} = useContext(DommusContext);
  const anotacaoRef = useRef(null);

  const handleOnSubmit = ()=>{
    let valorAnotacao = anotacaoRef?.current?.innerHTML ?? null;
    if(valorAnotacao != null){
      AtendimentoService.salvarAnotacao(token, atendimentoAtivo?.id, valorAnotacao).then((response)=>{
        setNoteList(response.data)
        anotacaoRef.current.innerHTML = "";
      }).catch((error)=>{
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao salvar a anotação  " + error,
          icon: "error",
        })
      });
    }
  }

  const handleExcluirAnotacao = (note) => {
    Swal.fire({
      titleText: "Remover anotação",
      text: "Tem certeza que deseja remover essa anotação?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, remover!",
      cancelButtonText: "Não",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((res) => {
      if (res.value) {
        AtendimentoService.excluirAnotacao(token, atendimentoAtivo.id, note.id)
        .then((response)=>{
            setNoteList(response.data);
        }).catch((error)=>{
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao salvar a anotação  " + error,
            icon: "error",
          })
        });
      }
    });
  }

  return (
    <div className="card-anotations">
      <div className="card-anotation-content">
        <div className="anotations-header">
          <div className="anotation-header-title">
            <FontAwesomeIcon icon={faCommentDots} />
            <span>Anotações</span>
          </div>
          <button
            className="header-btn-close"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} color="gray" />
          </button>
        </div>
        <div className="anotations-body">
          {
            noteList.map((note, index)=>{
              return <ItemAnotations note={note} key={index} handleExclusao={handleExcluirAnotacao} setNoteList={setNoteList}/>
            })
          }
        </div>
        <div className="anotation-input-section">
          <span className="textarea" role="textbox" contentEditable ref={anotacaoRef}></span>
          <button type="button" onClick={()=>{handleOnSubmit()}}>
            <Icon
              icon="material-symbols:send-outline-rounded"
              style={{ fontSize: "1.2rem" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
