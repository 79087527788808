import { formatDate } from "../utils/date";
import api from "./api";

const ChatService = {
  getChatHistory: function (token, pagina = 1) {
    const currentDate = new Date();
    return api.get(
      `/atendimento?pagina=${pagina}&filtros=%7B%22data_inicio%22:%22${formatDate(
        currentDate
      )}%22,%22data_fim%22:%22${formatDate(
        currentDate
      )}%22,%22meio_comunicacao%22:[5,2]%7D`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  },
  total: function (token) {
    const currentDate = new Date();
    return api.get(
      `/atendimento/total?filtros=%7B%22data_inicio%22:%22${formatDate(
        currentDate
      )}%22,%22data_fim%22:%22${formatDate(
        currentDate
      )}%22,%22meio_comunicacao%22:[5,2]%7D`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  },
  getChatMessages: function (token, id, page) {
    return api.get(`/atendimento/${id}/mensagens/${page}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  sendMessage: function (token, type, id_atendimento, body) {
    const requestBody = { id_atendimento, body };

    return api.post(`atendimento/${type}`, requestBody, {
      headers: {
        Authorization: token,
      },
    });
  },
};

export default ChatService;
