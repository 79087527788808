import React, { useState } from "react";
import "./voip-history.css"
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalHistory from "./ModalHistory";

export function VoipHistory() {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  return (
    <div className="btn-history">
      <FontAwesomeIcon
        icon={faHistory}
        color="white"
        onClick={() => {
            setIsHistoryOpen(!isHistoryOpen);
        }}
        size="lg"
      />
      <ModalHistory isModalOpen={isHistoryOpen} setIsModalOpen={setIsHistoryOpen} /> 
    </div>
  );
}
