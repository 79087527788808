import React, {createContext, useEffect, useState, useContext} from "react";
import Pusher from "pusher-js";
import { DommusContext } from "./DommusContext";

const pusher = new Pusher('7a75db53116fa8b81005', {
  cluster: 'us3'
});

export const PusherContext = createContext({});

export function PusherProvider ({children}) {
  const [channel, setChannel] = useState(pusher);
  const { tenancy } = useContext(DommusContext);


  useEffect(() => {
    const channel_name = `inquilino-${String(tenancy).padStart(4, "0")}`;
    if(tenancy){
      setChannel(channel.subscribe(channel_name))
    }
  }, [tenancy])

  return (
    <PusherContext.Provider value={{
      channel
      }}>
      {children}
    </PusherContext.Provider>
  ) 
}