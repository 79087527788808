import React from "react";
import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom/client";
import App from "./App";
const wrapper = document.getElementById('dommus-widgets-shadow')?.shadowRoot || document;
const container = wrapper.getElementById('dommus-widgets');
const root = createRoot(container);
const tenancy = container.getAttribute("data-tenancy");
const token = container.getAttribute("data-token");
const user = container.getAttribute("data-user");

root.render(<App tenancy={tenancy} token={token} user={user} />);
