import api from "./api";
const AtendimentoService = {
  find: (id, token) => {
    return api.get(`/atendimento/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  salvarAnotacao: (token, idAtendimento, anotacao, idAnotacao=null) => {
    let headers = {
        Authorization: token,
    }
    if(idAnotacao === null){
      return api.post(`/atendimento/anotacao/${idAtendimento}`, {anotacao}, {headers});
    }else{
      return api.put(`/atendimento/anotacao/${idAtendimento}/${idAnotacao}`, {anotacao}, {headers});
    }
  },
  excluirAnotacao: (token, idAtendimento, idAnotacao) => {
    let headers = {
        Authorization: token,
    }
    return api.delete(`/atendimento/anotacao/${idAtendimento}/${idAnotacao}`, {headers});
  }

}
export default AtendimentoService;
